import React from 'react';
import { Button, Logo } from '../../components';

// Styles
import './Start.scss';

const Start = () => {

  return (
    <div className="start">
      <Logo />

      <Button
        className="start__btn-game"
        to="/game"
        label="start game"
        neumorphism
      />
      <Button
        className="start__btn-highscore"
        to="/"
        label="Highscore"
        ghost
      />

      <Button
        className="start__btn-instruction"
        to="/"
        label="?"
        circle
      />
    </div>
  )
}

export default Start;
