import React from 'react';
import { Link } from 'react-router-dom';

import './Button.scss';

const Button = (props: IButtonProps) => {
  const { className, to, label, neumorphism, ghost, circle } = props;

  return (
    <Link
      className={className}
      to={to}
    >
      <button
        className={`
          btn
          ${neumorphism ? 'btn--neumorphism' : ''}
          ${ghost ? 'btn--ghost' : ''}
          ${circle ? 'btn--circle' : ''}
        `}
      >{label}</button>
    </Link>
  )
}

interface IButtonProps {
  className?: string;
  circle?: boolean;
  ghost?: boolean;
  neumorphism?: boolean;
  to: string;
  label: string;
}

export default Button;
