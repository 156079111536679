import React from 'react';

import { ReactComponent as BingoLogo } from '../../assets/bingo-logo.svg';
import { ReactComponent as BingoLogoSolid } from '../../assets/bingo-logo-solid.svg';

const Logo = (props: ILogoProps) => {
  const { solid } = props;

  return (
    solid ? <BingoLogoSolid /> : <BingoLogo/>
  )
}

interface ILogoProps {
  solid?: boolean;
}

export default Logo;
