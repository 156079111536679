import React from 'react';

import { Button, Logo } from '../../components';

// Styles
import './Congratulation.scss';

const Congratulation = () => {

    return (
      <div className="congratulation">
        <h1>You got</h1>
        <ul className="congratulation__rainbow">
          <li class="congratulation__rainbow__layer"><Logo solid/></li>
          <li class="congratulation__rainbow__layer"><Logo solid/></li>
          <li class="congratulation__rainbow__layer"><Logo solid/></li>
          <li class="congratulation__rainbow__layer"><Logo solid/></li>
          <li class="congratulation__rainbow__layer"><Logo solid/></li>
          <li class="congratulation__rainbow__layer"><Logo solid/></li>
          <li class="congratulation__rainbow__layer"><Logo solid/></li>
        </ul>
        <p>Total time: 2 secs</p>
        <Button
          className="congratulation__btn-restart"
          to="/game"
          label="New Game"
          ghost
        />
      </div>
    );
}

export default Congratulation;
