import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Congratulation, Game, Start } from '..';

import './App.scss';

const App = () => {
  return (

    <Route render={({ location }) => {

      return (
        <Switch location={location}>
          <Route exact path="/" component={Start} />
          <Route path="/game" component={Game} />
          <Route path="/congratulation" component={Congratulation} />
        </Switch>
      )
    }} />
  )
}

export default App;
