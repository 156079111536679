import React from 'react';

import { Board, Button, Logo } from '../../components';

// Styles
import './Game.scss';

const Game = () => {

    return (
      <div className="bingo">
        <Logo />
        <div className="bingo__board">
          <Board />
        </div>

        <Button
          className="bingo__btn-instruction"
          to="/"
          label="?"
          circle
        />
      </div>
    );
}

export default Game;
